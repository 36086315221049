<template>
  <b-form-input type="text" name="daterange"></b-form-input>
</template>

<script>
import $ from "jquery";
import moment from "moment";

let startDate;
let endDate;
let type;
let disabled;
let autoUpdate;
let timePicker;

export default {
  name: "datepicker",
  props: {
    start: {
      type: String
    },
    end: {
      type: String
    },
    // if type == 'clear', autoupdate must contain false to avoid input values being filled
    type: {
      type: String
    },
    disable: {
      type: Boolean
    },
    autoUpdate: {
      type: Boolean
    },
    timePicker: {
      type: Boolean
    }
  },
  watch: {
    // Watch for changes in the "disable" prop
    disable(newValue) {
      disabled = newValue;
      const datepicker = $('input[name="daterange"]');
      this.applyDatePickerLogic(datepicker);
    },
    start(newValue) {
      if (!newValue) {
        const formatDefault = "YYYY-MM-DD";
        const formatTimepicker = "YYYY-MM-DD HH:mm:ss";
        const format = this.timePicker ? formatTimepicker : formatDefault;

        startDate = moment()
          .subtract(6, "days")
          .format(format);

        // Set the default values
        const datepicker = $('input[name="daterange"]');
        const defaultEndDate = moment();
        const defaultStartDate = moment().subtract(6, "days");

        datepicker.data("daterangepicker").setStartDate(defaultStartDate);
        datepicker.data("daterangepicker").setEndDate(defaultEndDate);
      }
    },
    end(newValue) {
      if (!newValue) {
        const formatDefault = "YYYY-MM-DD";
        const formatTimepicker = "YYYY-MM-DD HH:mm:ss";
        const format = this.timePicker ? formatTimepicker : formatDefault;
        endDate = moment().format(format);

        // Set the default values
        const datepicker = $('input[name="daterange"]');
        const defaultEndDate = moment();
        const defaultStartDate = moment().subtract(6, "days");

        datepicker.data("daterangepicker").setStartDate(defaultStartDate);
        datepicker.data("daterangepicker").setEndDate(defaultEndDate);
      }
    }
  },
  methods: {
    handleValue() {
      const formatDefault = "YYYY-MM-DD";
      const formatTimepicker = "YYYY-MM-DD HH:mm:ss";
      const format = this.timePicker ? formatTimepicker : formatDefault;

      const autoUpdateValue =
        typeof this.autoUpdate !== undefined ? this.autoUpdate : true;
      const timePickerValue =
        typeof this.timePicker !== undefined ? this.timePicker : false;

      if (this.start && this.end) {
        disabled = this.disable;
        type = this.type;
        autoUpdate = autoUpdateValue;
        timePicker = timePickerValue;
        endDate = moment(this.end, format);
        startDate = moment(this.start, format);
      } else if (this.type == "clear" && !this.start && !this.end) {
        disabled = this.disable;
        type = this.type;
        autoUpdate = autoUpdateValue;
        timePicker = timePickerValue;
      } else {
        disabled = this.disable;
        type = this.type;
        autoUpdate = autoUpdateValue;
        timePicker = timePickerValue;
        endDate = moment().format(format);
        startDate = moment()
          .subtract(6, "days")
          .format(format);
      }
    },
    applyDatePickerLogic(datepicker) {
      if (disabled && type) {
        datepicker.prop("disabled", true);

        // Set the default values
        const defaultEndDate = moment();
        const defaultStartDate = moment().subtract(6, "days");

        datepicker.data("daterangepicker").setStartDate(defaultStartDate);
        datepicker.data("daterangepicker").setEndDate(defaultEndDate);
      } else if (!disabled && type) {
        datepicker.prop("disabled", false);
      }
    }
  },
  async mounted() {
    this.handleValue();

    const onDateSelected = (start, end, isTime) => {
      const formatDefault = "YYYY-MM-DD";
      const formatTimepicker = "YYYY-MM-DD HH:mm:ss";

      if (start && end) {
        this.$emit("picker", {
          startDate: start.format(isTime ? formatTimepicker : formatDefault),
          endDate: end.format(isTime ? formatTimepicker : formatDefault)
        });
      }
    };

    $(function() {
      const isTypeClear = type == "clear";
      const isTypeClearValue =
        autoUpdate || !(isTypeClear && !startDate && !endDate);
      const formatDefault = "YYYY-MM-DD";
      const formatTimepicker = "YYYY-MM-DD HH:mm:ss";

      const datepickerOptions = {
        ranges: {
          Today: [moment().startOf("day"), moment().endOf("day")],
          Yesterday: [
            moment()
              .subtract(1, "days")
              .startOf("day"),
            moment()
              .subtract(1, "days")
              .endOf("day")
          ],
          "Last 7 Days": [
            moment()
              .subtract(6, "days")
              .startOf("day"),
            moment().endOf("day")
          ],
          "Last 30 Days": [
            moment()
              .subtract(29, "days")
              .startOf("day"),
            moment().endOf("day")
          ],
          "This Month": [
            moment()
              .startOf("month")
              .startOf("day"),
            moment()
              .endOf("month")
              .endOf("day")
          ],
          "Last Month": [
            moment()
              .subtract(1, "month")
              .startOf("month")
              .startOf("day"),
            moment()
              .subtract(1, "month")
              .endOf("month")
              .endOf("day")
          ]
        },

        alwaysShowCalendars: true,
        readonly: true,
        autoUpdateInput: isTypeClearValue,
        startDate,
        endDate,
        locale: {
          format: timePicker ? formatTimepicker : formatDefault
        }
      };

      if (!type) datepickerOptions.maxDate = moment();
      if (timePicker) datepickerOptions.timePicker = true;

      const datepicker = $('input[name="daterange"]');

      datepicker.daterangepicker(datepickerOptions, function(start, end) {
        if (isTypeClear) {
          $(this.element).val(
            start.format(formatTimepicker) +
              " - " +
              end.format(formatTimepicker)
          );
          onDateSelected(start, end, timePicker);
        } else {
          onDateSelected(start, end, timePicker);
        }
      });

      if (disabled && type) {
        datepicker.prop("disabled", true);
      } else if (!disabled && type) {
        datepicker.prop("disabled", false);
      }
    });
  }
};
</script>
