import Vue from "vue";
import Router from "vue-router";
import { store } from "../store/request";
export const BASE_ROUTE = "/";

// Login
const baseTitle = " :: Treasury Admin";

//  * Login
const Login = () => import("@/views/pages/Login");

// * Macadress
const Macadress = () => import("@/views/pages/Macaddress");

//  * Containers
const DefaultContainer = () => import("@/containers/DefaultContainer");

//  * Dashboard
const Dashboard = () => import("@/views/Dashboard");
const DashboardUser = () => import("@/views/DashboardDetailUser");
const DashboardTransaction = () => import("@/views/DashboardDetailTransaction");

//  * Profile
const Profile = () => import("@/views/pages/users/profile/Profile");

//  * Cron Monitoring
const CronMonitoring = () => import("@/views/pages/cronMonitoring");

//  * ICH Trade & Customer ID
const ICH = () => import("@/views/pages/ich");

//  * Articles
const Article = () => import("@/views/pages/article/Index");
const AddArticle = () => import("@/views/pages/article/Add");
const EditArticle = () => import("@/views/pages/article/Edit");

//  * Koin Nusantara Product
const DinarProduct = () => import("@/views/pages/koin/product/Index");
const AddDinar = () => import("@/views/pages/koin/product/Add");
const EditDinar = () => import("@/views/pages/koin/product/Edit");

//  * Koin Nusantara Product
const ListDesign = () => import("@/views/pages/giftcard/Index");
const AddDesign = () => import("@/views/pages/giftcard/Add");
const EditDesign = () => import("@/views/pages/giftcard/Edit");
const ViewDesign = () => import("@/views/pages/giftcard/View");

const ListDesignTransaction = () =>
  import("@/views/pages/giftcard/transaction/Index");
const ListDesignDetailTransaction = () =>
  import("@/views/pages/giftcard/transaction/Detail");

//  * Koin Nusantara Order
const DinarOrder = () => import("@/views/pages/koin/order/Index");
const AddOrder = () => import("@/views/pages/koin/order/Add");
const EditOrder = () => import("@/views/pages/koin/order/Edit");
const DinarOrderDetail = () => import("@/views/invoice/DinarOrder");

//  * Koin Nusantara Setting
const DinarSetting = () => import("@/views/pages/koin/DinarSetting");

//  * Gold Redeem
const GoldReedem = () => import("@/views/pages/redeem/Index");
const EditRedeem = () => import("@/views/pages/redeem/Edit");
const DetailRedeem = () => import("@/views/invoice/GoldRedeem");

//  * Jewelry Product
const JewelryProduct = () => import("@/views/pages/jewelry/product/Index");
const AddJewelry = () => import("@/views/pages/jewelry/product/Add");
const EditJewelry = () => import("@/views/pages/jewelry/product/Edit");

//  * Jewelry Category
const JewelryCategories = () => import("@/views/pages/jewelry/category/Index");
const AddCategory = () => import("@/views/pages/jewelry/category/Add");
const EditCategory = () => import("@/views/pages/jewelry/category/Edit");

//  * Jewelry Order
const JewelryOrder = () => import("@/views/pages/jewelry/order/Index");
const JewelryOrderDetail = () => import("@/views/invoice/JewelryOrder");
const EditJewelryOrder = () => import("@/views/pages/jewelry/order/Edit");

// * Jamimas request
const JamimasRequest = () => import("@/views/pages/jamimas/request/Index");
const JamimasRequestDetail = () =>
  import("@/views/pages/jamimas/request/Detail");

// * Jamimas Transaction
const JamimasTransaction = () =>
  import("@/views/pages/jamimas/transaction/Index");
const JamimasTransactionDetail = () =>
  import("@/views/pages/jamimas/transaction/Detail");

//  * Mobile Banner
const MobileBanner = () => import("@/views/pages/banner/Index");
const AddBanner = () => import("@/views/pages/banner/Add");
const EditBanner = () => import("@/views/pages/banner/Edit");

//  * Splash Screen
const SplashScreen = () => import("@/views/pages/splash/Index");
const AddSplash = () => import("@/views/pages/splash/Add");
const EditSplash = () => import("@/views/pages/splash/Edit");

// * Alert
const Alert = () => import("@/views/pages/alert/Index");
const AddAlert = () => import("@/views/pages/alert/Add");
const EditAlert = () => import("@/views/pages/alert/Edit");

//  * Pages
const Page = () => import("@/views/pages/page/Index");
const AddPage = () => import("@/views/pages/page/Add");
const EditPage = () => import("@/views/pages/page/Edit");

//  * Pricing
const Pricing = () => import("@/views/pages/Pricing");
const AddPricing = () => import("@/views/pages/pricing/AddPricing");
const DetailPricing = () => import("@/views/pages/pricing/Detail");

//  * CryptoPricing
const CryptoPricing = () => import("@/views/pages/CryptoPricing");
const AddCryptoPricing = () => import("@/views/pages/crypto/Add");
const DetailCryptoPricing = () => import("@/views/pages/crypto/Detail");

//  * Push Notification
const PushNotification = () => import("@/views/pages/PushNotification");

//  * Revenue
const Revenue = () => import("@/views/pages/revenue/Index");

// * Normalization Transaction
const NormalizationTransaction = () =>
  import("@/views/pages/normalizationTransaction/Create");

//  * Shipping Cost
const ShippingCost = () => import("@/views/pages/shipping/Index");
const AddShippingCost = () => import("@/views/pages/shipping/Add");
const EditShippingCost = () => import("@/views/pages/shipping/Update");
const ShippingCostSAP = () => import("@/views/pages/shipping/sap/Index");
const AddShippingCostSAP = () => import("@/views/pages/shipping/sap/Add");
const EditShippingCostSAP = () => import("@/views/pages/shipping/sap/Update");

// * Crypto
const CryptoProduct = () => import("@/views/pages/crypto/product/Index");
const AddCryptoProduct = () => import("@/views/pages/crypto/product/Add");
const EditCryptoProduct = () => import("@/views/pages/crypto/product/Update");

//  * System Error Logs
const SystemLogError = () => import("@/views/pages/error/Index");

//  * System Error Logs
const Log = () => import("@/views/pages/log/Index");

//  * Tax
const Tax = () => import("@/views/pages/tax/Index");

//  * Transaction History
const Gold = () => import("@/views/pages/transaction/gold/Index");
const DetailGoldTransaction = () =>
  import("@/views/pages/transaction/gold/Detail");
const VoucherTransaction = () =>
  import("@/views/pages/transaction/voucher/Index");
const DetailVoucherTransaction = () =>
  import("@/views/pages/transaction/voucher/Detail");
const WalletTransaction = () =>
  import("@/views/pages/transaction/celengan/Index");
const DetailWalletTransaction = () =>
  import("@/views/pages/transaction/celengan/Detail");
const CryptoTransaction = () =>
  import("@/views/pages/transaction/crypto/Index");
const DetailCryptoTransaction = () =>
  import("@/views/pages/transaction/crypto/Detail");
const PartnerTransaction = () =>
  import("@/views/pages/transaction/partner/Index");
const DetailPartnerTransaction = () =>
  import("@/views/pages/transaction/partner/Detail");
const StakingTransaction = () =>
  import("@/views/pages/transaction/staking/Index");
const DetailStakingTransaction = () =>
  import("@/views/pages/transaction/staking/Detail");
const ReportTransaction = () =>
  import("@/views/pages/transaction/report/Index");

// Daily Report BAPPEBTI
const dailyReport = () => import("@/views/pages/dailyReport/Index");

// Addons
const AddOns = () => import("@/views/pages/addOns/addOnsSetup/Index");
const CreateAddOns = () => import("@/views/pages/addOns/addOnsSetup/Add");
const DetailAddons = () => import("@/views/pages/addOns/addOnsSetup/Detail");
const EditAddons = () => import("@/views/pages/addOns/addOnsSetup/Edit");
const TransactionAddons = () =>
  import("@/views/pages/addOns/transactionHistory/Index");
const DetailTransactionAddons = () =>
  import("@/views/pages/addOns/transactionHistory/Detail");

//  * Users & ACL
const EndUser = () => import("@/views/pages/users/end/Index");
const Agent = () => import("@/views/pages/users/agent/Index");
const DetailEndUser = () => import("@/views/pages/users/end/DetailEndUser");
const Permission = () => import("@/views/pages/users/permission/Index");
const AddPermission = () => import("@/views/pages/users/permission/Add");
const Role = () => import("@/views/pages/users/role/Index");
const AddRole = () => import("@/views/pages/users/role/Add");
const EditRole = () => import("@/views/pages/users/role/Edit");
const TemanTreasury = () => import("@/views/pages/users/temanTreasury/Index");
const HistoryTemanTreasury = () => import("@/views/pages/users/history/Index");
const DetailHistoryTemanTreasury = () =>
  import("@/views/pages/users/history/DetailHistoryTemanTreasury");
const DetailTemanTreasury = () =>
  import("@/views/pages/users/temanTreasury/DetailTemanTreasury");
const PointTemanTreasury = () => import("@/views/pages/users/point/Index");
const SuperUser = () => import("@/views/pages/users/superUser/Index");
const AddUser = () => import("@/views/pages/users/superUser/Add");
const EditUser = () => import("@/views/pages/users/superUser/Edit");
const VerifikasiKTP = () => import("@/views/pages/users/verify/Index");
const DetailVerifikasiKTP = () =>
  import("@/views/pages/users/verify/DetailVerify");
const VerifyPartner = () => import("@/views/pages/users/verifyPartner/Index");
const DetailVerifyPartner = () =>
  import("@/views/pages/users/verifyPartner/DetailVerify");

//  * Usership
const TotalUser = () => import("@/views/pages/usership/total/TotalUser");
const Active = () => import("@/views/pages/usership/active/Active");
const ActiveUserBuy = () => import("@/views/pages/usership/active/buy/Buy");
const ActiveUserSell = () => import("@/views/pages/usership/active/sell/Sell");
const ActiveUserGram = () => import("@/views/pages/usership/active/gram/Gram");
const ActiveUserMoney = () =>
  import("@/views/pages/usership/active/money/Money");
const NewUser = () => import("@/views/pages/usership/new/New");
const SilentUser = () => import("@/views/pages/usership/silent/Silent");
const DeadUser = () => import("@/views/pages/usership/dead/Dead");
const UsershipSetting = () => import("@/views/pages/usership/Setting");

//  * Voucher
const Voucher = () => import("@/views/pages/voucher/Index");
const Genarate = () => import("@/views/pages/voucher/Generate");
const DetailVoucher = () => import("@/views/pages/voucher/DetailVoucher");
const Qrcode = () => import("@/views/pages/voucher/Qrcode");
const VoucherImport = () => import("@/views/pages/voucher/Import");

// Voucher Junk
const VoucherJunk = () => import("@/views/pages/voucherJunk/Index");
const DetailVoucherJunk = () =>
  import("@/views/pages/voucherJunk/DetailVoucher");

//  * Voucher Partner
const VoucherPartner = () => import("@/views/pages/voucherpartner/Index");
const VoucherPartnerDetail = () =>
  import("@/views/pages/voucherpartner/DetailVoucherPartner");
const VoucherPartnerReport = () =>
  import("@/views/pages/voucherpartner/ReportVoucherPartner");
const VoucherPartnerSettlement = () =>
  import("@/views/pages/voucherpartner/settlement/Index");
const VoucherPartnerBalance = () =>
  import("@/views/pages/voucherpartner/balance/Index");
const VoucherPartnerHistory = () =>
  import("@/views/pages/voucherpartner/balance/HistoryTransaction");

// * Voucher Partnership
const VoucherPartnership = () =>
  import("@/views/pages/voucher/partnership/Index");
const GenaratePartnership = () =>
  import("@/views/pages/voucher/partnership/Generate");
const EditPartnership = () => import("@/views/pages/voucher/partnership/Edit");
const VoucherPartnershipDetail = () =>
  import("@/views/pages/voucher/partnership/detail/Index");

const Promotion = () => import("@/views/pages/promotion/Index");
const GenaratePromotion = () => import("@/views/pages/promotion/Generate");
const EditPromotion = () => import("@/views/pages/promotion/Edit");
const DetailPromotion = () => import("@/views/pages/promotion/Detail");

const PromotionCode = () => import("@/views/pages/promotion/code/Index");
const GenaratePromotionCode = () =>
  import("@/views/pages/promotion/code/Generate");
const DetailPromotionCode = () => import("@/views/pages/promotion/code/Detail");
const EditPromotionCode = () => import("@/views/pages/promotion/code/Edit");

// * treasury army

const PointTransaction = () =>
  import("@/views/pages/army/PointTransaction/Index");
const DetailPointTransaction = () =>
  import("@/views/pages/army/PointTransaction/Detail");
const UserArmy = () => import("@/views/pages/army/UserArmy/Index");
const DetailUserArmy = () => import("@/views/pages/army/UserArmy/Detail");
const AddUserArmy = () => import("@/views/pages/army/UserArmy/Add");
const EditUserArmy = () => import("@/views/pages/army/UserArmy/Edit");
const ReleasePoint = () => import("@/views/pages/army/ReleasePoint/Index");
const DetailReleasePoint = () =>
  import("@/views/pages/army/ReleasePoint/Detail");

//  * Withdraw
// const Withdraw = () => import('@/views/pages/withdraw/Index')

//  * Withdraw Sell
const WithdrawSell = () => import("@/views/pages/withdrawSell/Index");
const DetailWithdrawSell = () =>
  import("@/views/pages/withdrawSell/DetailWithdrawSell");
const Release = () => import("@/views/pages/withdrawSell/Release");

const GoldSellPartner = () => import("@/views/pages/goldsellpartner/Index");
const DetailGoldSellPartner = () =>
  import("@/views/pages/goldsellpartner/DetailGoldSellPartner");

const GoldSellWallet = () => import("@/views/pages/goldsellwallet/Index");
const DetailGoldSellWallet = () =>
  import("@/views/pages/goldsellwallet/DetailGoldSellWallet");

const WalletSell = () => import("@/views/pages/wallet/Index");
const DetailWalletSell = () => import("@/views/pages/wallet/Detail");

const SandBox = () => import("@/views/pages/sandbox/Index");

const BackgroundShare = () => import("@/views/pages/background/Index");
const AddBackgroundShare = () => import("@/views/pages/background/Add");
const EditBackgroundShare = () => import("@/views/pages/background/Edit");

const Content = () => import("@/views/pages/content/Index");
const AddContent = () => import("@/views/pages/content/Add");
const EditContent = () => import("@/views/pages/content/Edit");

const Report = () => import("@/views/pages/ReportTransaction/Index");
const Filter = () => import("@/views/pages/FilterTransaction/Index");
const Analyst = () => import("@/views/pages/analyst/Index");
const CryptoAnalyst = () => import("@/views/pages/analyst/crypto/Index");
const WalletAnalyst = () => import("@/views/pages/analyst/wallet/Index");
const WalletMonitor = () => import("@/views/pages/wallet/monitor/Index");
const DetailWalletMonitor = () => import("@/views/pages/wallet/monitor/Detail");

const Digital = () => import("@/views/pages/reseller/Index");
const AddDigital = () => import("@/views/pages/reseller/Generate");
const EditDigital = () => import("@/views/pages/reseller/Edit");
const DigitalDetail = () => import("@/views/pages/reseller/detail/Index");

const Fisik = () => import("@/views/pages/reseller/fisik/Index");
const AddFisik = () => import("@/views/pages/reseller/fisik/Generate");
const AddFisikDetail = () =>
  import("@/views/pages/reseller/fisik/detail/Generate");
const UpdateFisik = () => import("@/views/pages/reseller/fisik/Edit");
const DetailFisik = () => import("@/views/pages/reseller/fisik/detail/Index");

const BuyVoucher = () => import("@/views/pages/reseller/transaction/Index");
const DetailBuyVoucher = () =>
  import("@/views/pages/reseller/transaction/Detail");
const UpdateBuyVoucher = () =>
  import("@/views/pages/reseller/transaction/Update");

const UserReseller = () => import("@/views/pages/reseller/user/Index");
const DetailUserReseller = () => import("@/views/pages/reseller/user/Detail");

const Poup = () => import("@/views/pages/popup/Index");
const AddPopup = () => import("@/views/pages/popup/Add");
const EditPopup = () => import("@/views/pages/popup/Edit");

const PoupGoldBuy = () => import("@/views/pages/popup/buyGold/Index");
const AddPopupGoldBuy = () => import("@/views/pages/popup/buyGold/Add");
const EditPopupGoldBuy = () => import("@/views/pages/popup/buyGold/Edit");

const PoupNewUser = () => import("@/views/pages/popup/newUser/Index");
const AddPopupNewUser = () => import("@/views/pages/popup/newUser/Add");
const EditPopupNewUser = () => import("@/views/pages/popup/newUser/Edit");

// * Error Pages
const Page403 = () => import("@/views/pages/Page403");
const Page404 = () => import("@/views/pages/Page404");
const Page500 = () => import("@/views/pages/Page500");

const Logout = () => import("@/views/pages/Logout");

const example = () => import("@/views/custom/DateRangePicker.vue");

async function partner(to, from, next) {
  await store.dispatch("user/getUserLogin");
  // let partner = store.getters.is_partner !== false ? true : false

  let password_expired =
    store.getters.password_expired !== false ? true : false;
  if (password_expired) {
    // console.log('is expired');
    next({
      path: "/profile"
    });
  } else {
    // console.log('not expired');
    next();
  }
}

Vue.use(Router);
export default new Router({
  mode: "history",
  base: BASE_ROUTE,
  linkActiveClass: "open active",
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      name: "Home",
      component: DefaultContainer,
      children: [
        {
          path: "dashboard",
          name: "Dashboard",
          component: Dashboard,
          meta: {
            requiresAuth: true,
            title: "Dashboard" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "detail-withdaw-sell/:id",
          name: "Detail Jual Emas",
          component: DetailWithdrawSell,
          meta: {
            requiresAuth: true,
            title: "Detail Jual Emas" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "detail-gold-sell-partner/:id&:user_id",
          name: "Detail Jual Emas Partner",
          component: DetailGoldSellPartner,
          meta: {
            requiresAuth: true,
            title: "Detail Jual Emas Partner" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "detail-gold-sell-wallet/:id",
          name: "Detail Jual Emas Celengan",
          component: DetailGoldSellWallet,
          meta: {
            requiresAuth: true,
            title: "Detail Jual Emas Celengan" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "detail-wallet-sell/:id",
          name: "Detail Withdraw Celenganku",
          component: DetailWalletSell,
          meta: {
            requiresAuth: true,
            title: "Detail Withdraw Celenganku" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "example",
          name: "Example",
          component: example,
          meta: {
            requiresAuth: true,
            title: "Example" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "dashboard-detail-user",
          name: "Dashboard Detail User",
          component: DashboardUser,
          meta: {
            requiresAuth: true,
            title: "Detail Statistics User" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "dashboard-detail-transaction",
          name: "Dashboard Detail Transaction",
          component: DashboardTransaction,
          meta: {
            requiresAuth: true,
            title: "Detail Statistics Transaction" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "article",
          name: "List Articles",
          component: Article,
          meta: {
            requiresAuth: true,
            title: "Articles" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "add-article",
          name: "Add Article",
          component: AddArticle,
          meta: {
            requiresAuth: true,
            title: "Add Article" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "edit-article/:id",
          name: "Edit Article",
          component: EditArticle,
          meta: {
            requiresAuth: true,
            title: "Edit Article" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "dinar-products",
          name: "List Koin Nusantara Product",
          component: DinarProduct,
          meta: {
            requiresAuth: true,
            title: "Product" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "add-dinar",
          name: "Add Koin Nusantara Product",
          component: AddDinar,
          meta: {
            requiresAuth: true,
            title: "Add Product" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "edit-dinar/:id/:product",
          name: "Edit Koin Nusantara Product",
          component: EditDinar,
          meta: {
            requiresAuth: true,
            title: "Edit Product" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "list-design",
          name: "List Design",
          component: ListDesign,
          meta: {
            requiresAuth: true,
            title: "List Design" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "add-design",
          name: "Add Category",
          component: AddDesign,
          meta: {
            requiresAuth: true,
            title: "Add Category" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "edit-design/:id",
          name: "Edit Category",
          component: EditDesign,
          meta: {
            requiresAuth: true,
            title: "Edit Category" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "view-design/:id",
          name: "View Category",
          component: ViewDesign,
          meta: {
            requiresAuth: true,
            title: "View Category" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "list-design-transaction",
          name: "List Transaction",
          component: ListDesignTransaction,
          meta: {
            requiresAuth: true,
            title: "List Transaction" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "list-design-transaction/:id",
          name: "Detail Hadiah Emas Transaction",
          component: ListDesignDetailTransaction,
          meta: {
            requiresAuth: true,
            title: "Detail Hadiah Emas Transaction" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "gold-reedems",
          name: "List Cetak Emas",
          component: GoldReedem,
          meta: {
            requiresAuth: true,
            title: "Cetak Emas" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "gold-reedems/edit/:id",
          name: "Edit Cetak Emas",
          component: EditRedeem,
          meta: {
            requiresAuth: true,
            title: "Edit Cetak Emas" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "gold-reedems/detail/:id",
          name: "Detail Cetak Emas",
          component: DetailRedeem,
          meta: {
            requiresAuth: true,
            title: "Detail Cetak Emas" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "dinar-orders",
          name: "List Koin Nusantara Order",
          component: DinarOrder,
          meta: {
            requiresAuth: true,
            title: "Koin Nusantara Order" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "add-dinar-order",
          name: "Add Koin Nusantara Order",
          component: AddOrder,
          meta: {
            requiresAuth: true,
            title: "Add Koin Nusantara Order" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "edit-dinar-order/:id",
          name: "Edit Koin Nusantara Order",
          component: EditOrder,
          meta: {
            requiresAuth: true,
            title: "Edit Koin Nusantara Order" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "dinar-order-details/:id",
          name: "Detail Koin Nusantara Order",
          component: DinarOrderDetail,
          meta: {
            requiresAuth: true,
            title: "Detail Koin Nusantara Order" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "dinar-settings",
          name: "Koin Nusantara Settings",
          component: DinarSetting,
          meta: {
            requiresAuth: true,
            title: "Koin Nusantara Settings" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "jewelry-list",
          name: "Jewelry List",
          component: JewelryProduct,
          meta: {
            requiresAuth: true,
            title: "Jewelry List" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "add-jewelry",
          name: "Add Jewelry",
          component: AddJewelry,
          meta: {
            requiresAuth: true,
            title: "Add Jewelry Product" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "edit-jewelry/:id",
          name: "Edit Jewelry",
          component: EditJewelry,
          meta: {
            requiresAuth: true,
            title: "Edit Jewelry Product" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "jewelry-categories",
          name: "Jewelry Categories",
          component: JewelryCategories,
          meta: {
            requiresAuth: true,
            title: "Jewelry Categories" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "add-jewelry-category",
          name: "Add Jewelry Category",
          component: AddCategory,
          meta: {
            requiresAuth: true,
            title: "Add Jewelry Category" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "edit-jewelry-category/:id",
          name: "Edit Jewelry Category",
          component: EditCategory,
          meta: {
            requiresAuth: true,
            title: "Edit Jewelry Category" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "jewelry-orders",
          name: "Jewelry Orders",
          component: JewelryOrder,
          meta: {
            requiresAuth: true,
            title: "Jewelry Orders" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "jewelry-order-details/:id",
          name: "Jewelry Order Detail",
          component: JewelryOrderDetail,
          meta: {
            requiresAuth: true,
            title: "Detail Jewelry Order" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "edit-jewelry-order/:id",
          name: "Edit Jewelry Order",
          component: EditJewelryOrder,
          meta: {
            requiresAuth: true,
            title: "Edit Jewelry Order" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "mobile-banner",
          name: "Mobile Banner",
          component: MobileBanner,
          meta: {
            requiresAuth: true,
            title: "Mobile Banner" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "add-banner",
          name: "Add Banner",
          component: AddBanner,
          meta: {
            requiresAuth: true,
            title: "Add Banner" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "edit-banner/:id",
          name: "Edit Banner",
          component: EditBanner,
          meta: {
            requiresAuth: true,
            title: "Edit Banner" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "splash-screen",
          name: "Splash Screen",
          component: SplashScreen,
          meta: {
            requiresAuth: true,
            title: "Splash Screen" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "add-splash",
          name: "Add Splash Screen",
          component: AddSplash,
          meta: {
            requiresAuth: true,
            title: "Add Splash Screen" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "edit-splash/:id",
          name: "Edit Splash Screen",
          component: EditSplash,
          meta: {
            requiresAuth: true,
            title: "Edit Splash Screen" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "alert",
          name: "Alert",
          component: Alert,
          meta: {
            requiresAuth: true,
            title: "Alert" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "add-alert",
          name: "Add Alert",
          component: AddAlert,
          meta: {
            requiresAuth: true,
            title: "Add Alert" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "edit-alert/:id",
          name: "Edit Alert",
          component: EditAlert,
          meta: {
            requiresAuth: true,
            title: "Edit Alert" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "page",
          name: "Page",
          component: Page,
          meta: {
            requiresAuth: true,
            title: "Page" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "add-page",
          name: "Add Page",
          component: AddPage,
          meta: {
            requiresAuth: true,
            title: "Add Page" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "edit-page/:id",
          name: "Edit Page",
          component: EditPage,
          meta: {
            requiresAuth: true,
            title: "Edit Page" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "crypto-pricing",
          name: "Crypto Pricing Treasury",
          component: CryptoPricing,
          meta: {
            requiresAuth: true,
            title: "Crypto Pricing" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "add-crypto-pricing",
          name: "Add Crypto Pricing",
          component: AddCryptoPricing,
          meta: {
            requiresAuth: true,
            title: "Add Crypto Pricing" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "detail-crypto/:id",
          name: "Detail Crypto Pricing",
          component: DetailCryptoPricing,
          meta: {
            requiresAuth: true,
            title: "Detail Crypto Pricing" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "pricing",
          name: "Pricing Treasury",
          component: Pricing,
          meta: {
            requiresAuth: true,
            title: "Pricing" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "add-pricing",
          name: "Add Pricing",
          component: AddPricing,
          meta: {
            requiresAuth: true,
            title: "Add Pricing" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "detail-pricing/:id",
          name: "Detail Pricing",
          component: DetailPricing,
          meta: {
            requiresAuth: true,
            title: "Detail Pricing" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "push-notification",
          name: "Push Notification",
          component: PushNotification,
          meta: {
            requiresAuth: true,
            title: "Push Notification" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "revenue",
          name: "Revenue",
          component: Revenue,
          meta: {
            requiresAuth: true,
            title: "Revenue" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "shipping-cost",
          name: "Shipping Cost RPX",
          component: ShippingCost,
          meta: {
            requiresAuth: true,
            title: "Shipping Cost RPX" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "add-shipping-cost",
          name: "Add Shipping Cost RPX",
          component: AddShippingCost,
          meta: {
            requiresAuth: true,
            title: "Add Shipping Cost RPX" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "edit-shipping-cost/:id",
          name: "Edit Shipping Cost RPX",
          component: EditShippingCost,
          meta: {
            requiresAuth: true,
            title: "Edit Shipping Cost RPX" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "shipping-cost-sap",
          name: "Shipping Cost SAP",
          component: ShippingCostSAP,
          meta: {
            requiresAuth: true,
            title: "Shipping Cost SAP" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "add-shipping-cost-sap",
          name: "Add Shipping Cost SAP",
          component: AddShippingCostSAP,
          meta: {
            requiresAuth: true,
            title: "Add Shipping Cost SAP" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "edit-shipping-cost-sap/:id",
          name: "Edit Shipping Cost SAP",
          component: EditShippingCostSAP,
          meta: {
            requiresAuth: true,
            title: "Edit Shipping Cost SAP" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "crypto-products",
          name: "Crypto Product",
          component: CryptoProduct,
          meta: {
            requiresAuth: true,
            title: "Crypto Product" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "add-crypto-product",
          name: "Add Crypto Product",
          component: AddCryptoProduct,
          meta: {
            requiresAuth: true,
            title: "Add Crypto Product" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "edit-crypto-product/:id",
          name: "Edit Crypto Product",
          component: EditCryptoProduct,
          meta: {
            requiresAuth: true,
            title: "Edit Crypto Product" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "log-error",
          name: "System Logs Error",
          component: SystemLogError,
          meta: {
            requiresAuth: true,
            title: "System Logs Error" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "activity-log",
          name: "Activity Log",
          component: Log,
          meta: {
            requiresAuth: true,
            title: "Activity Log" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "gold",
          name: "Gold Transaction",
          component: Gold,
          meta: {
            requiresAuth: true,
            title: "Gold Transaction" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "detail-gold-transaction/:id",
          name: "Detail Gold Transaction",
          component: DetailGoldTransaction,
          meta: {
            requiresAuth: true,
            title: "Detail Gold Transaction" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "report-transaction-history",
          name: "Report Transaction History",
          component: ReportTransaction,
          meta: {
            requiresAuth: true,
            title: "Report Transaction" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "daily-report",
          name: "Daily Report BAPPEBTI",
          component: dailyReport,
          meta: {
            requiresAuth: true,
            title: "Daily Report BAPPEBTI" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "addons",
          name: "Add Ons Setup",
          component: AddOns,
          meta: {
            requiresAuth: true,
            title: "Add Ons Setup" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "create-addons",
          name: "Create Addons",
          component: CreateAddOns,
          meta: {
            requiresAuth: true,
            title: "Create Addons" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "update-addons/:id",
          name: "Add Ons Edit",
          component: EditAddons,
          meta: {
            requiresAuth: true,
            title: "Add Ons Edit" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "detail-addons/:id",
          name: "Add Ons Detail",
          component: DetailAddons,
          meta: {
            requiresAuth: true,
            title: "Add Ons Detail" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "transaction-addons",
          name: "Transaction History Add Ons",
          component: TransactionAddons,
          meta: {
            requiresAuth: true,
            title: "Transaction History" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "detail-transaction-addons/:id",
          name: "Add Ons Transaction History Detail",
          component: DetailTransactionAddons,
          meta: {
            requiresAuth: true,
            title: "Add Ons Transaction History Detail" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "voucher-transaction",
          name: "Voucher Transaction",
          component: VoucherTransaction,
          meta: {
            requiresAuth: true,
            title: "Voucher Transaction" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "detail-voucher-transaction/:id",
          name: "Detail Voucher Transaction",
          component: DetailVoucherTransaction,
          meta: {
            requiresAuth: true,
            title: "Detail Voucher Transaction" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "voucher-partner",
          name: "Voucher Partner",
          component: VoucherTransaction,
          meta: {
            requiresAuth: true,
            title: "Voucher Partner" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "wallet-transaction",
          name: "Wallet Transaction History",
          component: WalletTransaction,
          meta: {
            requiresAuth: true,
            title: "Wallet Transaction History" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "detail-wallet-transaction/:id",
          name: "Detail Wallet Transaction History",
          component: DetailWalletTransaction,
          meta: {
            requiresAuth: true,
            title: "Detail Wallet Transaction History" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "crypto-transaction",
          name: "Crypto Transaction History",
          component: CryptoTransaction,
          meta: {
            requiresAuth: true,
            title: "Crypto Transaction History" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "detail-crypto-transaction/:id",
          name: "Detail Crypto Transaction History",
          component: DetailCryptoTransaction,
          meta: {
            requiresAuth: true,
            title: "Detail Crypto Transaction History" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "partner-transaction",
          name: "Partner Transaction",
          component: PartnerTransaction,
          meta: {
            requiresAuth: true,
            title: "Partner Transaction" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "detail-partner-transaction/:id",
          name: "Detail Partner Transaction",
          component: DetailPartnerTransaction,
          meta: {
            requiresAuth: true,
            title: "Detail Partner Transaction" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "staking-transaction",
          name: "Staking Transaction History",
          component: StakingTransaction,
          meta: {
            requiresAuth: true,
            title: "Staking Transaction History" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "detail-staking-transaction/:id/:detail",
          name: "Detail Staking Transaction History",
          component: DetailStakingTransaction,
          meta: {
            requiresAuth: true,
            title: "Detail Staking Transaction History" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "tax",
          name: "Tax",
          component: Tax,
          meta: {
            requiresAuth: true,
            title: "Tax" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "end-user",
          name: "End User",
          component: EndUser,
          meta: {
            requiresAuth: true,
            title: "Total Users" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "agent",
          name: "Agent",
          component: Agent,
          meta: {
            requiresAuth: true,
            title: "Total Agent" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "detail-end-user/:id",
          name: "Detail End User",
          component: DetailEndUser,
          meta: {
            requiresAuth: true,
            title: "Detail End User" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "permissions",
          name: "Permissions",
          component: Permission,
          meta: {
            requiresAuth: true,
            title: "Permissions" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "add-permission",
          name: "Add Permission",
          component: AddPermission,
          meta: {
            requiresAuth: true,
            title: "Add Permission" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "roles",
          name: "Roles",
          component: Role,
          meta: {
            requiresAuth: true,
            title: "Roles" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "add-role",
          name: "Add Role",
          component: AddRole,
          meta: {
            requiresAuth: true,
            title: "Add Role" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "edit-role/:id",
          name: "Edit Role",
          component: EditRole,
          meta: {
            requiresAuth: true,
            title: "Edit Role" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "super-user",
          name: "Super Users",
          component: SuperUser,
          meta: {
            requiresAuth: true,
            title: "Super Users" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "add-user",
          name: "Add User",
          component: AddUser,
          meta: {
            requiresAuth: true,
            title: "Add Super User" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "edit-user/:id",
          name: "Edit User",
          component: EditUser,
          meta: {
            requiresAuth: true,
            title: "Edit Super User" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "teman-treasury",
          name: "Teman Treasury",
          component: TemanTreasury,
          meta: {
            requiresAuth: true,
            title: "Teman Treasury" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "detail-teman-treasury/:id",
          name: "Detail Teman Treasury",
          component: DetailTemanTreasury,
          meta: {
            requiresAuth: true,
            title: "Detail Teman Treasury" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "history-teman-treasury",
          name: "History Teman Treasury",
          component: HistoryTemanTreasury,
          meta: {
            requiresAuth: true,
            title: "History Teman Treasury" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "detail-history-teman-treasury/:id",
          name: "Detail History Teman Treasury",
          component: DetailHistoryTemanTreasury,
          meta: {
            requiresAuth: true,
            title: "Detail History Teman Treasury" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "point-teman-treasury",
          name: "Point Teman Treasury",
          component: PointTemanTreasury,
          meta: {
            requiresAuth: true,
            title: "Point Teman Treasury" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "detail-verifikasi/:id",
          name: "Detail Verifikasi KYC",
          component: DetailVerifikasiKTP,
          meta: {
            requiresAuth: true,
            title: "Detail Verifikasi KYC" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "verifikasi",
          name: "Verifikasi KYC",
          component: VerifikasiKTP,
          meta: {
            requiresAuth: true,
            title: "Verifikasi KYC" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "detail-verify-partner/:id",
          name: "Detail Verifikasi Partner",
          component: DetailVerifyPartner,
          meta: {
            requiresAuth: true,
            title: "Detail Verifikasi Partner" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "verify-partner",
          name: "Verifikasi Partner",
          component: VerifyPartner,
          meta: {
            requiresAuth: true,
            title: "Verifikasi KYC" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "voucher",
          name: "Voucher",
          component: Voucher,
          meta: {
            requiresAuth: true,
            title: "Voucher" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "voucher-junk",
          name: "Voucher Junk",
          component: VoucherJunk,
          meta: {
            requiresAuth: true,
            title: "Voucher Junk" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "detail-voucher-junk/:id",
          name: "Detail Voucher Junk",
          component: DetailVoucherJunk,
          meta: {
            requiresAuth: true,
            title: "Detail Voucher Junk" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "add-voucher",
          name: "Add Voucher",
          component: Genarate,
          meta: {
            requiresAuth: true,
            title: "Add Voucher" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "detail-voucher/:id",
          name: "Detail Voucher",
          component: DetailVoucher,
          meta: {
            requiresAuth: true,
            title: "Detail Voucher" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "voucher-import",
          name: "Voucher Import",
          component: VoucherImport,
          meta: {
            requiresAuth: true,
            title: "Voucher Import" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "voucher-partnership",
          name: "Voucher Partnership",
          component: VoucherPartnership,
          meta: {
            requiresAuth: true,
            title: "Voucher Partnership" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "add-voucher-partnership",
          name: "Add Voucher Partnership",
          component: GenaratePartnership,
          meta: {
            requiresAuth: true,
            title: "Add Voucher Partnership" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "edit-voucher-partnership/:id",
          name: "Edit Voucher Partnership",
          component: EditPartnership,
          meta: {
            requiresAuth: true,
            title: "Edit Voucher Partnership" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "detail-voucher-partnership/:id",
          name: "Detail Voucher Partnership",
          component: VoucherPartnershipDetail,
          meta: {
            requiresAuth: true,
            title: "Detail Voucher Partnership" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "partner-voucher",
          name: "Voucher Partner List",
          component: VoucherPartner,
          meta: {
            requiresAuth: true,
            title: "Voucher Partner" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "voucher-settlement/report",
          name: "Voucher Report",
          component: VoucherPartnerReport,
          meta: {
            requiresAuth: true,
            title: "Voucher Report" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "voucher-settlement",
          name: "Voucher Settlement",
          component: VoucherPartnerSettlement,
          meta: {
            requiresAuth: true,
            title: "Voucher Settlement" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "partner-voucher-detail/:id",
          name: "Detail Voucher Partner",
          component: VoucherPartnerDetail,
          meta: {
            requiresAuth: true,
            title: "Detail Voucher Partner" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "voucher-manage-balance",
          name: "Voucher Manage Balance",
          component: VoucherPartnerBalance,
          meta: {
            requiresAuth: true,
            title: "Manage Balance" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "voucher-manage-balance/history-transaction",
          name: "Voucher History Transaction",
          component: VoucherPartnerHistory,
          meta: {
            requiresAuth: true,
            title: "History Transaction" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "promotion-codes",
          name: "Promotion Codes",
          component: PromotionCode,
          meta: {
            requiresAuth: true,
            title: "Promo Code" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "add-promotion-code",
          name: "Add Promotion Code",
          component: GenaratePromotionCode,
          meta: {
            requiresAuth: true,
            title: "Add Promo Code" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "edit-promotion-code/:id",
          name: "Edit Promo Code",
          component: EditPromotionCode,
          meta: {
            requiresAuth: true,
            title: "Edit Promo Code" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "detail-promotion-code/:id",
          name: "Detail Promo Code",
          component: DetailPromotionCode,
          meta: {
            requiresAuth: true,
            title: "Detail Promo Code" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "promotion",
          name: "Promotion",
          component: Promotion,
          meta: {
            requiresAuth: true,
            title: "Promosi" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "add-promotion",
          name: "Add Promotion",
          component: GenaratePromotion,
          meta: {
            requiresAuth: true,
            title: "Add Promosi" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "edit-promotion/:id",
          name: "Edit Promotion",
          component: EditPromotion,
          meta: {
            requiresAuth: true,
            title: "Edit Promosi" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "detail-promotion/:id",
          name: "Detail Promotion",
          component: DetailPromotion,
          meta: {
            requiresAuth: true,
            title: "Detail Promosi" + baseTitle
          },
          beforeEnter: partner
        },
        // {
        //   path: 'withdraw',
        //   name: 'Withdraw',
        //   component: Withdraw,
        //   meta: {
        //     requiresAuth: true,
        // internal: true,
        //     title: 'Withdraw' + baseTitle
        //   }
        // },
        {
          path: "withdaw-sell",
          name: "Jual Emas",
          component: WithdrawSell,
          meta: {
            requiresAuth: true,
            title: "Jual Emas" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "gold-sell-partner",
          name: "Jual Emas Partner",
          component: GoldSellPartner,
          meta: {
            requiresAuth: true,
            title: "Jual Emas Partner" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "gold-sell-wallet",
          name: "Jual Emas Celengan",
          component: GoldSellWallet,
          meta: {
            requiresAuth: true,
            title: "Jual Emas Celengan" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "wallet-sell",
          name: "Withdraw Celenganku",
          component: WalletSell,
          meta: {
            requiresAuth: true,
            title: "Withdraw Celenganku" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "release",
          name: "Release",
          component: Release,
          meta: {
            requiresAuth: true,
            title: "Release " + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "profile",
          name: "Profile",
          component: Profile,
          meta: {
            requiresAuth: true,
            title: "Profile" + baseTitle
          }
        },
        // Usership
        {
          path: "total-user",
          name: "Total User",
          component: TotalUser,
          meta: {
            requiresAuth: true,
            title: "Total User" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "active-user",
          name: "Active User",
          component: Active,
          meta: {
            requiresAuth: true,
            title: "Active User" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "buy-transaction",
          name: "Active User Buy Transaction",
          component: ActiveUserBuy,
          meta: {
            requiresAuth: true,
            title: "Active User Buy Transaction" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "sell-transaction",
          name: "Active User Sell Transaction",
          component: ActiveUserSell,
          meta: {
            requiresAuth: true,
            title: "Active User Sell Transaction" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "gram-purchase",
          name: "Active User Gram Purchase",
          component: ActiveUserGram,
          meta: {
            requiresAuth: true,
            title: "Active User Gram Purchase" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "money-spent",
          name: "Active User Money Spent",
          component: ActiveUserMoney,
          meta: {
            requiresAuth: true,
            title: "Active User Money Spent" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "new-user",
          name: "New User",
          component: NewUser,
          meta: {
            requiresAuth: true,
            title: "New User" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "silent-user",
          name: "Silent User",
          component: SilentUser,
          meta: {
            requiresAuth: true,
            title: "Silent User" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "dead-user",
          name: "Dead User",
          component: DeadUser,
          meta: {
            requiresAuth: true,
            title: "Dead User" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "usership-setting",
          name: "Usership Setting",
          component: UsershipSetting,
          meta: {
            requiresAuth: true,
            title: "Usership Setting" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "sandbox",
          name: "Sandbox",
          component: SandBox,
          meta: {
            requiresAuth: true,
            title: "SandBox " + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "background-share",
          name: "Background image Share",
          component: BackgroundShare,
          meta: {
            requiresAuth: true,
            title: "Background Image Share" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "add-background-share",
          name: "Add Background Image Share",
          component: AddBackgroundShare,
          meta: {
            requiresAuth: true,
            title: "Add Background Image Share" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "edit-background-share/:id",
          name: "Edit Background Image Share",
          component: EditBackgroundShare,
          meta: {
            requiresAuth: true,
            title: "Edit Background Image Share" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "pop-up",
          name: "Pop Up",
          component: Poup,
          meta: {
            requiresAuth: true,
            title: "Pop Up" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "add-pop-up",
          name: "Add Pop Up",
          component: AddPopup,
          meta: {
            requiresAuth: true,
            title: "Add Pop Up" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "edit-pop-up/:id",
          name: "Edit Pop Up",
          component: EditPopup,
          meta: {
            requiresAuth: true,
            title: "Edit Pop Up" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "pop-up-beli-emas",
          name: "Pop Up Beli Emas",
          component: PoupGoldBuy,
          meta: {
            requiresAuth: true,
            title: "Pop Up Beli Emas" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "add-pop-up-beli-emas",
          name: "Add Pop Up Beli Emas",
          component: AddPopupGoldBuy,
          meta: {
            requiresAuth: true,
            title: "Add Pop Up Beli Emas" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "edit-pop-up-beli-emas/:id",
          name: "Edit Pop Up Beli Emas",
          component: EditPopupGoldBuy,
          meta: {
            requiresAuth: true,
            title: "Edit Pop Up Beli Emas" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "pop-up-home-page",
          name: "Pop Up Home Page",
          component: PoupNewUser,
          meta: {
            requiresAuth: true,
            title: "Pop Up Home Page" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "add-pop-up-home-page",
          name: "Add Pop Up Home Page",
          component: AddPopupNewUser,
          meta: {
            requiresAuth: true,
            title: "Add Pop Up Home Page" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "edit-pop-up-home-page/:id",
          name: "Edit Pop Up Home Page",
          component: EditPopupNewUser,
          meta: {
            requiresAuth: true,
            title: "Edit Pop Up Home Page" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "user-reseller",
          name: "Voucher User Reseller",
          component: UserReseller,
          meta: {
            requiresAuth: true,
            title: "Voucher User Reseller" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "user-reseller/:id",
          name: "Detail User Reseller",
          component: DetailUserReseller,
          meta: {
            requiresAuth: true,
            title: "Detail User Reseller" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "digital",
          name: "Voucher Digital Reseller",
          component: Digital,
          meta: {
            requiresAuth: true,
            title: "Voucher Digital Reseller" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "add-digital",
          name: "Generate Voucher Digital",
          component: AddDigital,
          meta: {
            requiresAuth: true,
            title: "Generate Voucher Digital" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "edit-digital/:id",
          name: "Edit Voucher Digital",
          component: EditDigital,
          meta: {
            requiresAuth: true,
            title: "Edit Voucher Digital" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "digital-reseller/:id",
          name: "Voucher Digital Reseller Detail",
          component: DigitalDetail,
          meta: {
            requiresAuth: true,
            title: "Voucher Digital Reseller Detail" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "physical",
          name: "Voucher Fisik Reseller",
          component: Fisik,
          meta: {
            requiresAuth: true,
            title: "Voucher Fisik Reseller" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "add-physical",
          name: "Generate Voucher Fisik",
          component: AddFisik,
          meta: {
            requiresAuth: true,
            title: "Generate Voucher Fisik" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "add-physical/detail/:id",
          name: "Generate Voucher Fisik Detail",
          component: AddFisikDetail,
          meta: {
            requiresAuth: true,
            title: "Generate Voucher Fisik Detail" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "edit-physical/:id",
          name: "Edit Voucher Physical",
          component: UpdateFisik,
          meta: {
            requiresAuth: true,
            title: "Edit Voucher Physical" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "detail-physical/:id",
          name: "Detail Voucher Physical",
          component: DetailFisik,
          meta: {
            requiresAuth: true,
            title: "Detail Voucher Physical" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "buy-voucher",
          name: "Transaksi Beli Voucher",
          component: BuyVoucher,
          meta: {
            requiresAuth: true,
            title: "Transaksi Beli Voucher" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "update-buy-voucher/:id",
          name: "Update Transaksi Beli Voucher",
          component: UpdateBuyVoucher,
          meta: {
            requiresAuth: true,
            title: "Update Transaksi Beli Voucher" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "buy-voucher/:id",
          name: "Detail Transaksi Beli Voucher",
          component: DetailBuyVoucher,
          meta: {
            requiresAuth: true,
            title: "Detail Transaksi Beli Voucher" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "quotes",
          name: "Quotes",
          component: Content,
          meta: {
            requiresAuth: true,
            title: "Quotes" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "add-quotes",
          name: "Add Quotes",
          component: AddContent,
          meta: {
            requiresAuth: true,
            title: "Add Quotes" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "edit-quotes/:id",
          name: "Edit Quotes",
          component: EditContent,
          meta: {
            requiresAuth: true,
            title: "Edit Quotes" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "report-transaction",
          name: "Report Transaction",
          component: Report,
          meta: {
            requiresAuth: true,
            title: "Report Transaction" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "analyst",
          name: "Analyst",
          component: Analyst,
          meta: {
            requiresAuth: true,
            title: "Analyst" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "crypto-analyst",
          name: "Crypto Analyst",
          component: CryptoAnalyst,
          meta: {
            requiresAuth: true,
            title: "Crypto Analyst" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "wallet-analyst",
          name: "Celenganku Analyst",
          component: WalletAnalyst,
          meta: {
            requiresAuth: true,
            title: "Celenganku Analyst" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "wallet-monitor",
          name: "Monitor Celengan",
          component: WalletMonitor,
          meta: {
            requiresAuth: true,
            title: "Monitor Celengan" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "wallet-monitor-detail/:id",
          name: "Detail Monitor Celengan",
          component: DetailWalletMonitor,
          meta: {
            requiresAuth: true,
            title: "Detail Monitor Celengan" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "filter-transaction",
          name: "Filter Transaction",
          component: Filter,
          meta: {
            requiresAuth: true,
            title: "Filter Transaction" + baseTitle
          },
          beforeEnter: partner
        },
        {
          path: "jamimas-request",
          name: "Request Jaminan Emas",
          component: JamimasRequest,
          meta: {
            requiresAuth: true,
            title: "Data Table History Jaminan Emas Transaction"
          },
          beforeEnter: partner
        },
        {
          path: "jamimas-request/:id",
          name: "Jamimas Request Detail",
          component: JamimasRequestDetail,
          meta: {
            requiresAuth: true,
            title: "Jaminan Emas Transaction Detail"
          },
          beforeEnter: partner
        },
        {
          path: "jamimas-transaction",
          name: "Jaminan Emas Transaction",
          component: JamimasTransaction,
          meta: {
            requiresAuth: true,
            title: "Jaminan Emas History Transaction"
          },
          beforeEnter: partner
        },
        {
          path: "jamimas-transaction/:id",
          name: "Jamimas Transaction Detail",
          component: JamimasTransactionDetail,
          meta: {
            requiresAuth: true,
            title: "Jaminan Emas Details"
          },
          beforeEnter: partner
        },
        {
          path: "normalisasi-transaksi",
          name: "Normalisasi Transaksi",
          component: NormalizationTransaction,
          meta: {
            requiresAuth: true,
            title: "Normalisasi Transaksi"
          },
          beforeEnter: partner
        },
        {
          path: "point-transaction",
          name: "Point Transaction",
          component: PointTransaction,
          meta: {
            requiresAuth: true,
            title: "Poin Transaksi"
          },
          beforeEnter: partner
        },
        {
          path: "point-transaction/:id",
          name: "Point Transaction Detail",
          component: DetailPointTransaction,
          meta: {
            requiresAuth: true,
            title: "Detail Poin Transaksi"
          },
          beforeEnter: partner
        },
        {
          path: "user-army",
          name: "User Army",
          component: UserArmy,
          meta: {
            requiresAuth: true,
            title: "User Army"
          },
          beforeEnter: partner
        },
        {
          path: "user-army/detail/:id",
          name: "User Army Detail",
          component: DetailUserArmy,
          meta: {
            requiresAuth: true,
            title: "Detail User Army"
          },
          beforeEnter: partner
        },
        {
          path: "user-army/edit/:id",
          name: "Edit User Army",
          component: EditUserArmy,
          meta: {
            requiresAuth: true,
            title: "Edit User Army"
          },
          beforeEnter: partner
        },
        {
          path: "user-army/add",
          name: "Add User Army",
          component: AddUserArmy,
          meta: {
            requiresAuth: true,
            title: "Add User Army"
          },
          beforeEnter: partner
        },
        {
          path: "release-point",
          name: "Release Point",
          component: ReleasePoint,
          meta: {
            requiresAuth: true,
            title: "Release Point"
          },
          beforeEnter: partner
        },
        {
          path: "release-point/:id",
          name: "Release Point Detail",
          component: DetailReleasePoint,
          meta: {
            requiresAuth: true,
            title: "Detail Release Point"
          },
          beforeEnter: partner
        },
        {
          path: "cron-monitoring",
          name: "Cron Monitoring",
          component: CronMonitoring,
          meta: {
            requiresAuth: true,
            title: "Cron Monitoring"
          },
          beforeEnter: partner
        },
        {
          path: "ich-trade",
          name: "ICH Trade & Customer ID",
          component: ICH,
          meta: {
            requiresAuth: true,
            title: "ICH Trade & Customer ID"
          },
          beforeEnter: partner
        }
      ]
    },
    {
      path: "/login",
      name: "Login",
      component: Login,
      meta: {
        requiresVisitor: true,
        title: "Login" + baseTitle
      }
    },
    {
      path: "/macadress",
      name: "Macaddress",
      component: Macadress,
      meta: {
        requiresVisitor: true,
        title: "Register Macadress" + baseTitle
      }
    },
    {
      path: "/qrcode/:id",
      name: "Qrcode",
      component: Qrcode,
      meta: {
        requiresAuth: true,
        internal: true,
        title: "Qrcode" + baseTitle
      },
      beforeEnter: (to, from, next) => {
        if (store.getters.password_expired) {
          next({ path: "/profile" });
        } else {
          next();
        }
      }
    },
    {
      path: "/404",
      component: Page404,
      meta: {
        title: "404" + baseTitle
      }
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      path: "/403",
      component: Page403,
      meta: {
        title: "403" + baseTitle
      }
    },
    {
      path: "/500",
      component: Page500,
      meta: {
        title: "500" + baseTitle
      }
    },
    {
      path: "/logout",
      name: "logout",
      component: Logout
    }
  ]
});
